@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto-Thin.ttf) format('ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto-Light.ttf) format('ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto-Regular.ttf) format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto-Medium.ttf) format('ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto-Bold.ttf) format('ttf');
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: #2F0073;
  color: #d2d2ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 990px;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 100%;
  display: flex;
}